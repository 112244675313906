












































import Vue from "vue";
import {Auth} from "@/services/auth";


export default Vue.extend({
  i18n: {
    messages: {
      en:{
        titulo: 'Recover Password',
        correo: 'Email',
        botonenviar: 'Send Email',
      },
      es: {
        titulo: 'Recuperar Contraseña',
        correo: 'Correo Electrónico',
        botonenviar: 'Enviar Correo',
      }
    }
  },
  data() {
    return {
      email: '',
      loading: false
    }
  },
  methods: {
    resetPassword() {
      if (!this.email) return;
      const request = {
        email: this.email
      }

      this.loading = true

      Auth.resetPassword(request)
          .then(() => {
            this.$notify({
              title: 'Success',
              text: 'email sent'
            });
          })
          .catch(reason => {
            const message = reason.response.data.errors[
                Object.keys(reason.response.data.errors)[0]
                ][0]
            this.$notify({
              title: 'Error',
              text: message,
              type: 'error'
            });
          })
          .finally(() => {
            this.loading = false
          })
    }
  }

})
