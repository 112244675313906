















































import Vue from "vue";
import Component from "vue-class-component";
import {mapGetters, mapState} from "vuex";
import {PetOwner} from "@/interfaces/PetOwner";
import {User} from "@/interfaces/User";

@Component({
  computed: {
    ...mapState('account', ['user', 'owner']),
    ...mapGetters('account', ['getRole', 'getOwnerActive']),
  },
  i18n: {
    messages: {
      en:{
        mensaje: 'Welcome',
        titulopopup: 'Register Your Pet Data',
        textpopup: 'Login to load the data associated with the code you registered',
        botonregistrar:'Register',
      },
      es: {
        mensaje: 'Bienvenido',
        titulopopup: 'Registra los Datos de tu Mascota',
        textpopup: 'Ingresa para cargar los datos asociados a código que registraste',
        botonregistrar:'Registrar',
      }
    }
  }
})
export default class Home extends Vue{
  user!: User
  owner!: PetOwner
  dialog = false
  getRole!: string
  getOwnerActive!: boolean

  get role() {
    return this.getRole
  }

  get active() {
    return this.getOwnerActive
  }

  mounted() {
    if (this.role === 'pet_owner' && !this.active) {
      this.dialog = true
    }
  }

  goRegistro() {
    this.$router.replace({name: 'RegistrarMascota', params: {code: this.owner.code_register}})
  }
}
