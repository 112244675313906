import {AxiosRequestConfig, AxiosResponse} from 'axios';
import qs from 'qs';
import Api from '@/services/Api';
import ApiService from '@/services/Api';
import {LocalStorage} from '@/services/LocalStorage';
import {RegisterRequest} from '@/interfaces/request/RegisterRequest';

const Auth = {
    login : async function (data: any): Promise<AxiosResponse>{

        const requestData: AxiosRequestConfig = {
            method: 'post',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: 'Basic ' + btoa(process.env.VUE_APP_CLIENT_ID + ':' + process.env.VUE_APP_CLIENT_SECRET)
            },
            baseURL: process.env.VUE_APP_ROOT_API,
            url: '/oauth/token',
            data: qs.stringify({
                'grant_type': 'password',
                username: data.username,
                password: data.password
            })
        };

        try {
            const response = await Api.customRequest(requestData);
            LocalStorage.saveToken(response.data.access_token);
            LocalStorage.saveRefreshToken(response.data.refresh_token);
            Api.setHeader();

            return response;
        } catch (error) {
            let status;
            let description;

            if (error.response === undefined) {
                status = error.message;
                description = error.message;
            } else {
                status = error.response.status;
                description = error.response.data.error_description;
            }
            throw new Error(description);
        }

    },

    register: async function (data: RegisterRequest): Promise<any> {
        const response = await Api.post('/auth/register', data)
        return response.data
    },

    validateCode: async function (data: { code: string }): Promise<any> {
        const response = await Api.post('/auth/validate', data)
        return response.data
    },
    logout: async function (): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            try {
                ApiService.removeHeader()
                LocalStorage.removeToken()
                LocalStorage.removeRefreshToken()
                resolve(true)
            } catch (e) {
                reject(e)
            }
        })
    },

    resetPassword: async function (data: {email: string}) : Promise<any> {
        const response = await Api.post('/auth/reset', data)
        return response.data
    },
    change_password: async function (data: any) : Promise<any> {
        const response = await Api.post('/auth/change_password', data)
        return response.data
    }


}

export {Auth}
