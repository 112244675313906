import {Module} from "vuex";

interface appState {
    drawer: boolean,
}

export const App: Module<appState, any> = {
    namespaced: true,
    state: {
        drawer: false
    },
    getters: {
        getDrawer: (state): boolean => {
            return state.drawer
        }
    },
    mutations: {
        toggleDrawer: (state) => {
            state.drawer = !state.drawer
        },
        setDrawer: (state, payload: boolean) => {
            state.drawer = payload
        }
    }
}
