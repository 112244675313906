import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import I18n from '@/i18n';
import '@fortawesome/fontawesome-free/css/all.css'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
      options: {
        customProperties: true
      },
    themes: {
      light: {
        primary: '#30bac7',
        secondary: '#eb9880',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        letras: '#4BABAC',
        azulclaro:'#D1E2EB',
        rosaclaro:'#FFC5F2',
        botonsito: '#FF6DDE',
      },
    },
  },
  lang: {
    t: (key, ...params) => I18n.t(key,params) as string
  },
  icons: {
    iconfont: 'fa',
  }
});
