<template>
  <v-row
      align="center"
      align-content="center"
      class="fill-height white"
      justify="center"
  >
    <v-progress-circular v-if="loading" class="align-self-center" color="primary" indeterminate size="150" width="2">
      <v-progress-circular color="secondary" indeterminate rotate="180" size="120"
                           width="1"></v-progress-circular>
    </v-progress-circular>
    <v-btn color="secondary" v-if="errorload" :to="{name: 'Home'}">{{ $t('boton') }}</v-btn>
  </v-row>
</template>

<script>
import {QrCodeService} from "@/services/QrCode";

export default {
  name: "LoadEscaner",
  props: {
    code: {
      type: String,
      default: ''
    }
  },
  i18n: {
    messages: {
      en: {
        boton: 'Home'
      },
      es: {
        boton: 'Inicio'
      }
    }
  },
  data() {
    return {
      loading: false,
      errorload: false
    }
  },
  mounted() {
    this.loading = true
    QrCodeService.statusEscaner({code: this.code})
        .then(value => {
          if (value.status === 'showcase') {
            this.$router.replace({name: 'preguntaescaner', params: {pet: value.data}})
          } else if (value.status === 'disabled') {
            this.$notify({
              title: 'Error',
              text: 'Codigo Invalido',
              type: 'error'
            });
            this.errorload = true
          } else {
            if (!value.data.pet) {
              this.$router.replace({name:'Home'})
            } else {
              this.$router.replace({name: 'detalleescaneo', params: {pet: value.data.pet, owner: value.data.user}})
            }
          }
        })
        .catch(() => {
          this.$notify({
            title: 'Error',
            text: 'Codigo Invalido',
            type: 'error'
          });
          this.errorload = true
        })
        .finally(() => {
          this.loading = false
        })
  }
}
</script>

<style scoped>

</style>
