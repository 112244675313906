import VueI18n, {IVueI18n} from "vue-i18n";

class AccountModel {

    I18nMessages() {
        return {
            en: {
                title: 'Profile',
                labels: {
                    username: 'Username',
                    firstName: 'First Name',
                    lastName: 'Last Name',
                    email: 'Email',
                    address: 'Address',
                    country: 'Country',
                    occupation: 'Occupation',
                    phone: 'Phone',
                    placeWork: 'Place Work'
                },
                buttons: {
                    accept: 'Update'
                }
            },
            es: {
                title: 'Perfil',
                labels: {
                    username: 'Usuario',
                    firstName: 'Nombres',
                    lastName: 'Apellidos',
                    email: 'Correo',
                    address: 'Dirección',
                    country: 'Pais',
                    occupation: 'Ocupación',
                    phone: 'Teléfono',
                    placeWork: 'Lugar de Trabajo'
                },
                buttons: {
                    accept: 'Actualizar'
                }
            }
        }
    }

    labels(I18n: VueI18n & IVueI18n) {
        return {
            username: () => I18n.t('labels.username'),
            firstName: () => I18n.t('labels.firstName'),
            lastName: () => I18n.t('labels.lastName'),
            email: () => I18n.t('labels.email'),
            address: () => I18n.t('labels.address'),
            country: () => I18n.t('labels.country'),
            occupation: () => I18n.t('labels.occupation'),
            phone: () => I18n.t('labels.phone'),
            placeWork: () => I18n.t('labels.placeWork')
        }

    }
}

const accountModel = new AccountModel()

export {accountModel}