import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@/scss/main.scss'
import Api from '@/services/Api';
import i18n from './i18n'
import Notifications from 'vue-notification'
Vue.config.productionTip = false

Api.init(process.env.VUE_APP_ROOT_API)

const mountProject = async () => {
    const components = await import(
        /* webpackChunkName: "components" */
        /* webpackPrefetch: true */
        '@/components/index')
    Vue.use(components.default)
    Vue.use(Notifications)
    new Vue({
        router,
        store,
        vuetify,
        i18n,
        render: h => h(App)
    }).$mount('#app')
}

mountProject().finally()
