import {Module} from 'vuex';
import {LocalStorage} from '@/services/LocalStorage';
import {Auth as AuthService} from '@/services/auth';
import {AxiosResponse} from 'axios';
import {RegisterRequest} from '@/interfaces/request/RegisterRequest';


interface AuthState {
    authenticating: boolean;
    accessToken: string | null;
    authenticationErrorCode: number;
    authenticationError: string;
    refreshTokenPromise: Promise<any> | null;
}


export const Auth: Module<AuthState, any> = {
    namespaced: true,
    state: {
        authenticating: false,
        accessToken: LocalStorage.getToken(),
        authenticationErrorCode: 0,
        refreshTokenPromise: null,
        authenticationError: ''
    },
    actions: {
        login: async ({commit}, signInData: any) => {
            commit('signInRequest');
            return new Promise((resolve, reject) => {
                AuthService.login(signInData).then((res) => {
                    commit('signInSuccess', res.data);
                    resolve(res);
                }).catch((err: any) => {
                    reject(err)
                });
            });
        },
        register: async ({commit}, payload: RegisterRequest) => {
            return new Promise((resolve, reject) => {
                AuthService.register(payload).then((res) => {
                    resolve(res);
                }).catch((err: AxiosResponse) => {
                    reject(err)
                });
            });
        },
        validateCode: async ({commit}, payload: { code: string }) => {
            return new Promise((resolve, reject) => {
                AuthService.validateCode(payload).then((res) => {
                    resolve(res);
                }).catch((err: AxiosResponse) => {
                    reject(err)
                });
            });
        },
        logout: async () => {
            return AuthService.logout()
        }

    },
    mutations: {
        signInRequest: state => {
            state.authenticating = true;
            state.authenticationError = '';
            state.authenticationErrorCode = 0;
        },
        signInSuccess: (state, payload: any) => {
            state.accessToken = payload.access_token;
            state.authenticating = false;
        }
    }


}
