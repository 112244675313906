






















































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import {petService} from '@/services/Pet';
import {mapMutations} from 'vuex';
import petModel from '@/models/PetModel.model';

export default Vue.extend({
  props: {
    code: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dialogphoto: false,
      dialog: true,
      loading: false,
      imagen: null,
      photo: '',
      loadingfoto: false,
      rules: petModel.rules(),
      registro: {
        qrcode: '',
        name: '',
        age: 0,
        specie: '',
        color: '',
        raza: '',
        genre: '',
        weight: '',
        unit_weight: '',
        observation: '',
        reward: '',
        estadoreproductivo: '',
        desparasitante: '',
        fechadesparasitante: '',
        observacionesmedicas: '',
        alergias: (new Array<{ nombre: string }>()),
        newalergia: '',
        enfermedades: (new Array<{ nombre: string, cuidado: string }>()),
        nombrenfermedad: '',
        cuidadoenfermedad: '',
        vacunas: (new Array<{ nombre: string, fecha: string }>()),
        nombrevacuna: '',
        fechavacuna: '',
        medicaciones: (new Array<{ nombre: string, dosis: string, frecuencia: string }>()),
        nombremedicacion: '',
        dosismedicacion: '',
        frecuenciamedicacion: '',
        tipoalimento: '',
        cantidadalimento: '',
        marcaalimento: '',
        contactos: (new Array<{ nombre: string, telefono: string, parentesco: string }>()),
        nombrecontacto: '',
        telefonocontacto: '',
        parentescocontacto: ''
      },
      fechaVacuna: false,
      loadingValidate: false,
      id: 0
    }
  },
  computed: {
    especies() {
      return [
        {
          text: this.$t('canino'),
          value: 'canine'
        },
        {
          text: this.$t('felino'),
          value: 'feline'

        },
        {
          text: this.$t('otro'),
          value: 'other'
        }
      ]
    },
    estadoreprodctivos() {
      return [
        {
          value: 'whole',
          text: this.$t('entero')
        },
        {
          value: 'sterilized',
          text: this.$t('esterilizado')
        },
        {
          value: 'castrated',
          text: this.$t('castrado')
        },
        {
          value: 'gestation',
          text: this.$t('gestacion')
        },
        {
          value: 'lactation',
          text: this.$t('lactancia')
        },
      ]
    }
  },
  mounted() {
    this.registro.qrcode = this.code
    if (this.registro.qrcode) {
      this.loadingValidate = true
      this.$nextTick(() => {
        this.validateQR()
      })

    }

  },
  i18n: {
    messages: {
      en: {
        titulo: 'Pet Data',
        nombre: 'Name',
        edad: 'Age',
        especie: 'Species',
        color: 'Color',
        raza: 'Breed  ',
        peso: 'Weight',
        observaciones: 'Observations',
        genero: 'Gender',
        macho: 'Male',
        hembra: 'Female',
        recompensa: 'Reward',
        si: 'Yes',
        no: 'No',
        canino: 'Canine',
        felino: 'Feline',
        otro: 'Other',
        titulopopup: 'Enter your pet QR code',
        codigo: 'Associated Code',
        botonvalidar: 'Validate',
        botoncancelar: 'Cancel',
        titulomedico: 'Medical Data',
        estadoreproductivo: 'Reproductive Status',
        nombredesparacitante: 'Dewormer Name',
        entero: 'Whole',
        esterilizado: 'Sterilized',
        castrado: 'Castrated',
        gestacion: 'Gestation',
        lactancia: 'Lactation',
        fechadesparacitante: 'Deworming Date',
        observacionesmedicas: 'Medical Observations',
        tituloalergia: 'Allergies',
        tituloalergia2: 'Allergy',
        nombrealergia: 'Allergy Name',
        botoneliminar: 'Remove',
        tituloingresaralergia: 'Enter Allergy',
        botoningresar: 'Enter',
        tituloenfermedad: 'Diseases',
        tituloenfermedad2: 'Disease',
        nombreenfermedad: 'Disease Name',
        cuidadoenfermedad: 'Disease Care',
        tituloingresarEnfermedad: 'Enter Disease',
        titulovacuna: 'Vaccines',
        titulovacuna2: 'Vaccine',
        nombrevacuna: 'Vaccine Name',
        fechavacuna: 'Vaccine Date',
        tituloingresarvacuna: 'Enter Vaccine',
        titulomedicacion: 'Medications',
        titulomedicacion2: 'Medication',
        nombremedicacion: 'Medication Name',
        dosismedicacion: 'Medication Dose',
        frecuenciamedicacion: 'Medication Frequency',
        tituloingresarmedicacion: 'Enter Medication',
        tituloalimentacion: 'Food Data',
        tipoalimento: 'Type of Food',
        cantidadalimento: 'Amount of Food',
        marcaalimento: 'Food Brand',
        titulocontacto: 'Contacts',
        titulocontacto2: 'Contact',
        nombrecontacto: 'Names',
        telefonocontacto: 'Phone',
        parentesco: 'Relationship',
        tituloingresarcontacto: 'Add Contact',
        titulograndecontacto: 'Other Contacts',
        botonguardar: 'Save',
        titulofoto: 'Pet Photo',
        botonsalir: 'Exit'
      },
      es: {
        titulo: 'Datos de Mascota',
        nombre: 'Nombre',
        edad: 'Edad',
        especie: 'Especie',
        color: 'Color',
        raza: 'Raza',
        peso: 'Peso',
        observaciones: 'Observaciones',
        genero: 'Genero',
        macho: 'Macho',
        hembra: 'Hembra',
        recompensa: '¿Ofrece Recompensa?',
        si: 'Si',
        no: 'No',
        canino: 'Canino',
        felino: 'Felino',
        otro: 'Otro',
        titulopopup: 'Ingresa el Código del QR de tu Mascota',
        codigo: 'Código del QR Asociado',
        botonvalidar: 'Validar',
        botoncancelar: 'Cancelar',
        titulomedico: 'Datos Médicos',
        estadoreproductivo: 'Estado Reproductivo',
        nombredesparacitante: 'Nombre Desparacitante',
        entero: 'Entero',
        esterilizado: 'Esterilizado',
        castrado: 'Castrado',
        gestacion: 'Gestación',
        lactancia: 'Lactancia',
        fechadesparacitante: 'Fecha del Desparacitante',
        observacionesmedicas: 'Observaciones Médicas',
        tituloalergia: 'Alergias',
        tituloalergia2: 'Alergia',
        nombrealergia: 'Nombre de Alergia',
        botoneliminar: 'Eliminar',
        tituloingresaralergia: 'Ingresar Alergia',
        botoningresar: 'Ingresar',
        tituloenfermedad: 'Enfermedades',
        tituloenfermedad2: 'Enfermedad',
        nombreenfermedad: 'Nombre de la Enfermedad',
        cuidadoenfermedad: 'Cuidados de la Enfermedad',
        tituloingresarEnfermedad: 'Ingresar Enfermedad',
        titulovacuna: 'Vacunas',
        titulovacuna2: 'Vacuna',
        nombrevacuna: 'Nombre de la Vacuna',
        fechavacuna: 'Fecha de la Vacuna',
        tituloingresarvacuna: 'Ingresar Vacuna',
        titulomedicacion: 'Medicaciones',
        titulomedicacion2: 'Medicación',
        nombremedicacion: 'Nombre del Medicamento',
        dosismedicacion: 'Dosis',
        frecuenciamedicacion: 'Frecuencia',
        tituloingresarmedicacion: 'Ingresar Medicación',
        tituloalimentacion: 'Datos Alimenticios',
        tipoalimento: 'Tipo de Alimento',
        cantidadalimento: 'Cantidad de Alimento',
        marcaalimento: 'Marca del Alimento',
        titulocontacto: 'Contactos',
        titulocontacto2: 'Contacto',
        nombrecontacto: 'Nombres',
        telefonocontacto: 'Telefono',
        parentesco: 'Parentesco',
        tituloingresarcontacto: 'Añadir Contacto',
        titulograndecontacto: 'Otros Contactos',
        botonguardar: 'Guardar',
        titulofoto: 'Foto de la Mascota',
        botonsalir: 'Salir'
      }
    }
  },
  methods: {
    ...mapMutations('account', ['setOwnerActive']),
    formValidateCode(): Vue & { validate: () => boolean, reset(): void } {
      return this.$refs.formvalidatecode as Vue & { validate: () => boolean, reset(): void }
    },
    validateQR() {
      if (this.formValidateCode().validate()) {
        const request = {
          code: this.registro.qrcode
        }
        this.loadingValidate = true
        petService.validateCodeToRegister(request)
            .then(() => {
              this.dialog = false
              this.$notify({
                title: 'Success'
              });
            })
            .catch(reason => {
              if (reason.response.status === 422) {
                const message = reason.response.data.errors[
                    Object.keys(reason.response.data.errors)[0]
                    ][0]
                this.$notify({
                  title: 'Error',
                  text: message,
                  type: 'error'
                });
              } else {
                this.$notify({
                  title: 'Error',
                  type: 'error'
                });
              }
            })
            .finally(() => {
              this.loadingValidate = false
            })
      }
    },
    formAlergia(): Vue & { validate: () => boolean, reset(): void } {
      return this.$refs.alergias as Vue & { validate: () => boolean, reset(): void }
    },
    agregaralergia() {
      if (!this.formAlergia().validate()) {
        return 0
      }
      if (this.registro.newalergia) {
        this.registro.alergias.push({
          nombre: this.registro.newalergia
        })
        this.formAlergia().reset()
      }
    },
    eliminarAlergia(index: number) {
      this.registro.alergias.splice(index, 1)
    },
    formEnfermedad(): Vue & { validate: () => boolean, reset(): void } {
      return this.$refs.enfermedades as Vue & { validate: () => boolean, reset(): void }
    },
    agregarEnfermedad() {
      if (!this.formEnfermedad().validate()) {
        return 0
      }
      if (this.registro.nombrenfermedad && this.registro.cuidadoenfermedad) {
        this.registro.enfermedades.push({
          nombre: this.registro.nombrenfermedad,
          cuidado: this.registro.cuidadoenfermedad
        })
        this.formEnfermedad().reset()
      }
    },
    eliminarEnfermedad(index: number) {
      this.registro.enfermedades.splice(index, 1)
    },
    formVacuna(): Vue & { validate: () => boolean, reset(): void } {
      return this.$refs.formvacuna as Vue & { validate: () => boolean, reset(): void }
    },
    agregarVacuna() {
      if (!this.formVacuna().validate()) {
        return 0
      }
      if (this.registro.nombrevacuna && this.registro.fechavacuna) {
        this.registro.vacunas.push({
          nombre: this.registro.nombrevacuna,
          fecha: this.registro.fechavacuna
        })
        this.formVacuna().reset()
      }
    },
    eliminarVacuna(index: number) {
      this.registro.vacunas.splice(index, 1)
    },
    formMedicacion(): Vue & { validate: () => boolean, reset(): void } {
      return this.$refs.medicaciones as Vue & { validate: () => boolean, reset(): void }
    },
    agregarMedicacion() {
      if (!this.formMedicacion().validate()) {
        return 0
      }
      if (this.registro.nombremedicacion && this.registro.dosismedicacion && this.registro.frecuenciamedicacion) {
        this.registro.medicaciones.push({
          nombre: this.registro.nombremedicacion,
          dosis: this.registro.dosismedicacion,
          frecuencia: this.registro.frecuenciamedicacion
        })
        this.formMedicacion().reset()
      }
    },
    eliminarMedicacion(index: number) {
      this.registro.medicaciones.splice(index, 1)
    },
    formContacto(): Vue & { validate: () => boolean, reset(): void } {
      return this.$refs.contacto as Vue & { validate: () => boolean, reset(): void }
    },
    agregarContacto() {
      if (!this.formContacto().validate()) {
        return 0
      }
      if (this.registro.nombrecontacto && this.registro.telefonocontacto && this.registro.parentescocontacto) {
        this.registro.contactos.push({
          nombre: this.registro.nombrecontacto,
          telefono: this.registro.telefonocontacto,
          parentesco: this.registro.parentescocontacto
        })
        this.formContacto().reset()
      }
    },
    eliminarContacto(index: number) {
      this.registro.contactos.splice(index, 1)
    },
    formRegistro(): Vue & { validate: () => boolean, reset(): void } {
      return this.$refs.basicdata as Vue & { validate: () => boolean, reset(): void }
    },
    regitrar() {
      if (!this.formRegistro().validate()) {
        return 0
      }
      this.loading = true
      petService.registro(this.registro)
          .then(value => {
            this.id = value.id
            this.dialogphoto = true
            this.$notify({
              title: 'Success',
              text: 'Code registered'
            });
            this.registro = {
              qrcode: '',
              name: '',
              age: 0,
              specie: '',
              color: '',
              raza: '',
              genre: '',
              weight: '',
              unit_weight: '',
              observation: '',
              reward: '',
              estadoreproductivo: '',
              desparasitante: '',
              fechadesparasitante: '',
              observacionesmedicas: '',
              alergias: (new Array<{ nombre: string }>()),
              newalergia: '',
              enfermedades: (new Array<{ nombre: string, cuidado: string }>()),
              nombrenfermedad: '',
              cuidadoenfermedad: '',
              vacunas: (new Array<{ nombre: string, fecha: string }>()),
              nombrevacuna: '',
              fechavacuna: '',
              medicaciones: (new Array<{ nombre: string, dosis: string, frecuencia: string }>()),
              nombremedicacion: '',
              dosismedicacion: '',
              frecuenciamedicacion: '',
              tipoalimento: '',
              cantidadalimento: '',
              marcaalimento: '',
              contactos: (new Array<{ nombre: string, telefono: string, parentesco: string }>()),
              nombrecontacto: '',
              telefonocontacto: '',
              parentescocontacto: ''
            }
            this.setOwnerActive(true)
          })
          .catch(reason => {
            if (reason.response.status === 422) {
              const message = reason.response.data.errors[
                  Object.keys(reason.response.data.errors)[0]
                  ][0]
              this.$notify({
                title: 'Error',
                text: message,
                type: 'error'
              });
            } else {
              this.$notify({
                title: 'Error',
                type: 'error'
              });
            }
          })
          .finally(() => {
            this.loading = false
          })

    },
    cancelar() {
      this.$router.replace({name: 'Home'})
    },
    cambiarfoto() {
      this.loadingfoto = true
      const formData = new FormData();
      formData.append('photo', this.imagen || '')
      petService.cambiarfoto(formData, this.id)
          .then(value => {
            this.photo = value.photo
            this.dialog = false
            this.$notify({
              title: 'Success'
            });
          })
          .catch(reason => {
            if (reason.response.status === 422) {
              const message = reason.response.data.errors[
                  Object.keys(reason.response.data.errors)[0]
                  ][0]
              this.$notify({
                title: 'Error',
                text: message,
                type: 'error'
              });
            } else {
              this.$notify({
                title: 'Error',
                type: 'error'
              });
            }
          })
          .finally(() => {
            this.loadingfoto = false
          })
    }
  }
})
