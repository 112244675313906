




















































































import Vue from "vue";
import Component from "vue-class-component";
import {QrCodeService} from "@/services/QrCode";

@Component({
  i18n: {
    messages: {
      en:{
        titulo: 'QR Codes',
        disponibles: 'QR Codes Available',
        vitrina: 'QR Codes in Showcase',
        vendidos: 'QR Codes Sold',
        botonlistado:'See List'
      },
      es: {
        titulo: 'Códigos QR',
        disponibles: 'Códigos QR Disponibles',
        vitrina: 'Códigos QR en Vitrina',
        vendidos: 'Códigos QR Vendidos',
        botonlistado:'Ver Listado'
      }
    }
  }
})
export default class QrMain extends Vue {
  loading = false
  disponibles = 0
  vitrina = 0
  vendidos = 0

  mounted() {
    this.loading = true
    QrCodeService.count()
        .then(value => {
          this.disponibles = value.available
          this.vitrina = value.showcase
          this.vendidos = value.vendidos
        })
        .finally(() => {
          this.loading = false
        })
  }

}
