import Vue from 'vue'
import Vuex from 'vuex'
import {Auth} from '@/store/Auth';
import {account} from "@/store/Account";
import {App} from "@/store/App";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    Auth,
    account,
    App
  }
})
