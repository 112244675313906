import Api from "@/services/Api";
import {Profile} from "@/interfaces/User";
import {UpdateProfile} from "@/interfaces/request/UpdateProfile";

const perfilService = {
    getInfo: async (): Promise<Profile> => {
        const response = await Api.get<Profile>('profile')
        return response.data
    },
    updateProfile: async (data: UpdateProfile): Promise<Profile> => {
        const response = await Api.post<Profile,UpdateProfile>('profile/update',data)
        return response.data
    }
}

export {perfilService}