import { es } from 'vuetify/src/locale'
export default {
    '$vuetify' : {
        ...es
    },
    model: {
        auth: {
            labels: {
                username: 'Usuario',
                password: 'Contraseña',
                passwordConfirmation: 'Confirmación de contraseña',
                email: 'Correo',
                firstName: 'Nombres',
                lastName: 'Apellidos'
            }
        }
    },
    form: {
        required: 'Es requerido',
        email: 'Email es invalido',
        max: {
            number: 'Maximo  {value}',
            character: 'Maximo de {value} caracteres'
        },
        min: {
            number: 'Minimo {value}',
            character: 'Minimo de  {value} caracteres'
        },
        checked: 'El check es requerido',
    },
    code: 'Codigo QR es requerido',
    yes: 'Si',
    no: 'No',
    M: 'Macho',
    F: 'Hembra'

}
