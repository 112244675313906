<template>
  <v-app>
    <nav-bar-auth/>
    <main-auth></main-auth>
    <notifications position="bottom right"/>
  </v-app>
</template>

<script>
import NavBarAuth from "@/components/layouts/nav-bar-auth";
import MainAuth from "@/components/layouts/main-auth";

export default {
  name: "layout-auth",
  components: {MainAuth, NavBarAuth}
}
</script>

<style scoped>

</style>
