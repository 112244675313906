
















































































































































import Vue from 'vue';
import {mapActions} from 'vuex';
import authModel from '@/models/Auth.model';


export default Vue.extend({
  name: 'registrar',
  props: {
    code: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      registerForm: {
        username: '',
        email: '',
        first_name: '',
        last_name: '',
        password: '',
        qrcode: '',
        name: '',
        age: 0,
        specie: '',
        color: '',
        breed: '',
        weight: '',
        genre: '',
        reward: ''
      },
      formValidate: false,
      formUser: false,
      formPet: false,
      loading: false,
      loadingValidate: false,
      rules: authModel.registerRules,
      labels: authModel.labels,
      step: 1
    }
  },
  i18n: {
    messages: {
      en:{
        titulo: 'Register',
        subtitulo: 'Validate QR Code',
        texto: 'Enter the code below the QR',
        codigo: 'Code',
        botonvalidar: 'Validate',
        titulo2: 'User Data',

      },
      es: {
        titulo: 'Registrar',
        subtitulo: 'Validar Código QR',
        texto: 'Ingresar el código que está debajo del QR',
        codigo: 'Código',
        botonvalidar: 'Validar',
        titulo2: 'Datos del Usuario',

      }
    }
  },
  mounted() {
    this.registerForm.qrcode = this.code
    if (this.code) {
      this.$nextTick(() => {
        this.RunvalidateCode()
      })
    }

  },
  methods: {
    ...mapActions('Auth', ['register', 'validateCode']),
    RunvalidateCode() {
      if (this.formValidateRef().validate()) {
        this.loadingValidate = true
        const request = {
          code: this.registerForm.qrcode
        }
        this.validateCode(request)
            .then(() => {
              this.step = 2
            })
            .catch(reason => {
              const message = reason.response.data.errors[
                  Object.keys(reason.response.data.errors)[0]
                  ][0]
              this.$notify({
                title: 'Error',
                text: message,
                type: 'error'
              });
            })
            .finally(() => {
              this.loadingValidate = false
            })
      }
    },
    next() {
      if (this.formUserRef().validate()){
        this.step = 3
      }
    },
    signUp() {
      if (this.formUserRef().validate()) {
        this.loading = true
        this.register(this.registerForm)
            .then(() => {
              this.$notify({
                title: 'Success',
                text: 'Code registered'
              });
              this.registerForm = {
                username: '',
                email: '',
                first_name: '',
                last_name: '',
                password: '',
                qrcode: '',
                name: '',
                age: 0,
                specie: '',
                color: '',
                breed: '',
                weight: '',
                genre: '',
                reward: ''
              }
              this.formUserRef().reset()
              this.$router.replace('/auth')
            })
            .catch(reason => {
              if (reason.response.status === 422){
                const message = reason.response.data.errors[
                    Object.keys(reason.response.data.errors)[0]
                    ][0]
                this.$notify({
                  title: 'Error',
                  text: message,
                  type: 'error'
                });
              }
              else {
                this.$notify({
                  title: 'Error',
                  type: 'error'
                });
              }
            })
            .finally(() => {
              this.loading = false
            })
      }
    },
    formValidateRef(): Vue & { validate: () => boolean, reset() : void } {
      return this.$refs.validate as Vue & { validate: () => boolean, reset() : void }
    },
    formUserRef(): Vue & { validate: () => boolean, reset() : void } {
      return this.$refs.user as Vue & { validate: () => boolean, reset() : void }
    },
    formPetRef(): Vue & { validate: () => boolean, reset() : void } {
      return this.$refs.pet as Vue & { validate: () => boolean, reset() : void }
    }
  }
})
