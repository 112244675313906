import axios, {AxiosPromise, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse} from 'axios';
import {LocalStorage} from '@/services/LocalStorage';

const ApiService = {
    _requestInterceptor: 0,
    _401interceptor: 0,

    init(baseURL: string | undefined): void {
        axios.defaults.baseURL = baseURL + '/api';
        axios.defaults.headers.common[
            'Accept'
            ] = `application/json`;
    },

    setHeader(): void {
        axios.defaults.headers.common[
            'Authorization'
            ] = `Bearer ${LocalStorage.getToken()}`;
        axios.defaults.headers.common[
            'Accept'
            ] = `application/json`;
    },

    getHeader(): AxiosRequestHeaders {
        return axios.defaults.headers.common
    },

    removeHeader(): void {
        axios.defaults.headers.common = {};
        axios.defaults.headers.common[
            'Accept'
            ] = `application/json`;
    },

    get<T>(resource: string): Promise<AxiosResponse<T>> {
        return axios.get<T>(resource);
    },

    getOption<T>(resource: string,config: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return axios.get<T>(resource, config);
    },

    post<T, S>(resource: string, data: S): Promise<AxiosResponse<T>> {
        return axios.post<T>(resource, data);
    },

    postOption<T, S,D>(resource: string, data: S,config: AxiosRequestConfig<D>): Promise<AxiosResponse<T>> {
        return axios.post<T>(resource, data, config);
    },

    put<T, S>(resource: string, data: S): Promise<AxiosResponse<T>> {
        return axios.put<T>(resource, data);
    },

    delete<T>(resource: string): Promise<AxiosResponse<T>> {
        return axios.delete<T>(resource);
    },

    customRequest(data: AxiosRequestConfig): AxiosPromise {
        return axios(data);
    },
}

export default ApiService;
