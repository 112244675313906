




































import Vue from 'vue';

export default Vue.extend({
  name: 'nav-bar-qr',
  data() {
    return {
      lang: 'en'
    }
  },
  methods: {
    locale(value: string) {
      this.$root.$i18n.locale = value
    },
    goHome(){
      this.$router.replace({name:'Home'})
    }
  }
})
