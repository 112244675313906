<template>
  <v-main class="fondo">
    <v-container class="py-0 fill-height overflow-container overflow-auto overflow-x-hidden" style="max-height: calc(100vh - 65px)">
      <router-view/>
    </v-container>
  </v-main>
</template>
<script>
export default {
  name: "main-auth"
}
</script>

<style scoped>

</style>
