






















































import Vue from "vue";
import {petService} from "@/services/Pet";

export default Vue.extend({
  data() {
    return {
      pets: (new Array<any>()),
      loading: false
    }
  },
  i18n: {
    messages: {
      en:{
        titulo: 'My Pets',
      },
      es: {
        titulo: 'Mis Mascotas',
      }
    }
  },
  mounted() {
    this.loading = true
    petService.mismascotas()
    .then(value => {
      this.pets = value
    })
    .finally(() => {
      this.loading = false
    })
  }
})

