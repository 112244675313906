
































































































































































































































































































































































































































































































































/* eslint-disable no-undef */

import Vue from 'vue';
import {QrCodeService} from '@/services/QrCode';

export default Vue.extend({
  props: {
    code: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      registro: {
        qrcode: '',
        name: '',
        age: 0,
        specie: '',
        color: '',
        raza: '',
        weight: '',
        unit_weight: '',
        observation: '',
        reward: '',
        estadoreproductivo: '',
        desparasitante: '',
        fechadesparasitante: '',
        observacionesmedicas: '',
        alergias: (new Array<{ nombre: string }>()),
        newalergia: '',
        enfermedades: (new Array<{ nombre: string, cuidado: string }>()),
        nombrenfermedad: '',
        cuidadoenfermedad: '',
        vacunas: (new Array<{ nombre: string, fecha: string }>()),
        nombrevacuna: '',
        fechavacuna: '',
        medicaciones: (new Array<{ nombre: string, dosis: string, frecuencia: string }>()),
        nombremedicacion: '',
        dosismedicacion: '',
        frecuenciamedicacion: '',
        tipoalimento: '',
        cantidadalimento: '',
        marcaalimento: '',
        contactos: (new Array<{ nombre: string, telefono: string, parentesco: string }>()),
        nombrecontacto: '',
        telefonocontacto: '',
        parentescocontacto: '',
        nombredueño: '',
        telefonodueño: '',
        email: ''
      },
      owner: {},
      foto: ''
    }
  },
  i18n: {
    messages: {
      en: {
        titulo: 'Pet Data',
        nombre: 'Name',
        edad: 'Age',
        especie: 'Species',
        color: 'Color',
        raza: 'Breed',
        peso: 'Weight',
        observaciones: 'Observations',
        genero: 'Gender',
        macho: 'Male',
        hembra: 'Female',
        recompensa: 'Reward',
        si: 'Yes',
        no: 'No',
        canino: 'Canine',
        felino: 'Feline',
        otro: 'Other',
        titulopopup: 'Enter your pet QR code',
        codigo: 'Associated Code',
        botonvalidar: 'Validate',
        botoncancelar: 'Cancel',
        titulomedico: 'Medical Data',
        estadoreproductivo: 'Reproductive Status',
        nombredesparacitante: 'Dewormer Name',
        entero: 'Whole',
        esterilizado: 'Sterilized',
        castrado: 'Castrated',
        gestacion: 'Gestation',
        lactancia: 'Lactation',
        fechadesparacitante: 'Deworming Date',
        observacionesmedicas: 'Medical Observations',
        tituloalergia: 'Allergies',
        tituloalergia2: 'Allergy',
        nombrealergia: 'Allergy Name',
        botoneliminar: 'Remove',
        tituloingresaralergia: 'Enter Allergy',
        botoningresar: 'Enter',
        tituloenfermedad: 'Diseases',
        tituloenfermedad2: 'Disease',
        nombreenfermedad: 'Disease Name',
        cuidadoenfermedad: 'Disease Care',
        tituloingresarEnfermedad: 'Enter Disease',
        titulovacuna: 'Vaccines',
        titulovacuna2: 'Vaccine',
        nombrevacuna: 'Vaccine Name',
        fechavacuna: 'Vaccine Date',
        tituloingresarvacuna: 'Enter Vaccine',
        titulomedicacion: 'Medications',
        titulomedicacion2: 'Medication',
        nombremedicacion: 'Medication Name',
        dosismedicacion: 'Medication Dose',
        frecuenciamedicacion: 'Medication Frequency',
        tituloingresarmedicacion: 'Enter Medication',
        tituloalimentacion: 'Food Data',
        tipoalimento: 'Type of Food',
        cantidadalimento: 'Amount of Food',
        marcaalimento: 'Food Brand',
        titulocontacto: 'Contacts',
        titulocontacto2: 'Owner Contact',
        titulocontacto22: 'Contact',
        nombredueño: 'Names',
        telefonodueño: 'Phone',
        llamardueño: 'Call',
        emaildueño: 'Email',
        whatsappdueño: 'WhatsApp',
        nombrecontacto: 'Names',
        telefonocontacto: 'Phone',
        parentesco: 'Relationship',
        tituloingresarcontacto: 'Add Contact',
        titulograndecontacto: 'Other Contacts',
        botonguardar: 'Save',
        yes: 'Yes',
        M: 'Male',
        F: 'Female'
      },
      es: {
        titulo: 'Datos de Mascota',
        nombre: 'Nombre',
        edad: 'Edad',
        especie: 'Especie',
        color: 'Color',
        raza: 'Raza',
        peso: 'Peso',
        observaciones: 'Observaciones',
        genero: 'Genero',
        macho: 'Macho',
        hembra: 'Hembra',
        recompensa: '¿Ofrece Recompensa?',
        si: 'Si',
        no: 'No',
        canino: 'Canino',
        felino: 'Felino',
        otro: 'Otro',
        titulopopup: 'Ingresa el Código del QR de tu Mascota',
        codigo: 'Código del QR Asociado',
        botonvalidar: 'Validar',
        botoncancelar: 'Cancelar',
        titulomedico: 'Datos Médicos',
        estadoreproductivo: 'Estado Reproductivo',
        nombredesparacitante: 'Nombre Desparacitante',
        entero: 'Entero',
        esterilizado: 'Esterilizado',
        castrado: 'Castrado',
        gestacion: 'Gestación',
        lactancia: 'Lactancia',
        fechadesparacitante: 'Fecha del Desparacitante',
        observacionesmedicas: 'Observaciones Médicas',
        tituloalergia: 'Alergias',
        tituloalergia2: 'Alergia',
        nombrealergia: 'Nombre de Alergia',
        botoneliminar: 'Eliminar',
        tituloingresaralergia: 'Ingresar Alergia',
        botoningresar: 'Ingresar',
        tituloenfermedad: 'Enfermedades',
        tituloenfermedad2: 'Enfermedad',
        nombreenfermedad: 'Nombre de la Enfermedad',
        cuidadoenfermedad: 'Cuidados de la Enfermedad',
        tituloingresarEnfermedad: 'Ingresar Enfermedad',
        titulovacuna: 'Vacunas',
        titulovacuna2: 'Vacuna',
        nombrevacuna: 'Nombre de la Vacuna',
        fechavacuna: 'Fecha de la Vacuna',
        tituloingresarvacuna: 'Ingresar Vacuna',
        titulomedicacion: 'Medicaciones',
        titulomedicacion2: 'Medicación',
        nombremedicacion: 'Nombre del Medicamento',
        dosismedicacion: 'Dosis',
        frecuenciamedicacion: 'Frecuencia',
        tituloingresarmedicacion: 'Ingresar Medicación',
        tituloalimentacion: 'Datos Alimenticios',
        tipoalimento: 'Tipo de Alimento',
        cantidadalimento: 'Cantidad de Alimento',
        marcaalimento: 'Marca del Alimento',
        titulocontacto: 'Contactos',
        titulocontacto2: 'Contacto del Dueño',
        nombrecontacto: 'Nombres',
        telefonocontacto: 'Telefono',
        nombredueño: 'Nombres',
        telefonodueño: 'Telefono',
        llamardueño: 'Llamar',
        emaildueño: 'Correo',
        whatsappdueño: 'WhatsApp',
        parentesco: 'Parentesco',
        tituloingresarcontacto: 'Añadir Contacto',
        titulograndecontacto: 'Otros Contactos',
        botonguardar: 'Guardar',
        titulocontacto22: 'Contacto',
        yes: 'Si',
        M: 'Macho',
        F: 'Hembra'
      }
    }
  },
  methods: {
    success(position: GeolocationPosition) {
      console.log(position.coords.latitude)
      console.log(position.coords.longitude)
      QrCodeService.sendPosition({
        code: this.code,
        latitude: position.coords.latitude,
        longitude: position.coords.longitude
      })
    },
    error(error: GeolocationPositionError) {
      QrCodeService.sendPosition({
        code: this.code,
        latitude: '',
        longitude: ''
      })
    },
    geoFindMe() {
      if (!navigator.geolocation) {
        return;
      }
      navigator.geolocation.getCurrentPosition(this.success, this.error);
    }

  },
  computed: {
    genre() {
      if (!this.$route.params.pet) {
        return  ''
      } else {
        const pet: any = this.$route.params.pet
        return this.$t(pet.genre)
      }
    },
    reward() {
      if (!this.$route.params.pet) {
        return  ''
      } else {
        const pet: any = this.$route.params.pet
        return pet.reward ? this.$t('yes').toString() : this.$t('no').toString()
      }
    },
  },
  mounted() {
    if (!this.$route.params.pet) {
      this.$router.replace({name: 'loadescaner', params: {code: this.code}})
    } else {
      const pet: any = this.$route.params.pet
      this.foto = pet.photo
      const owner: any = this.$route.params.owner
      this.registro = {
        qrcode: '',
        name: pet.name,
        age: pet.age,
        specie: pet.species,
        color: pet.color,
        raza: pet.raza,
        weight: pet.weight,
        unit_weight: pet.unit_weight,
        observation: pet.remarks,
        reward: pet.reward ? this.$t('yes').toString() : this.$t('no').toString(),
        estadoreproductivo: pet.medical_data.reproactive_state,
        desparasitante: pet.medical_data.deworming,
        fechadesparasitante: pet.medical_data.date_deworming,
        observacionesmedicas: pet.medical_data.observation,
        alergias: pet.medical_data.allergys,
        newalergia: '',
        enfermedades: pet.medical_data.diaseases,
        nombrenfermedad: '',
        cuidadoenfermedad: '',
        vacunas: pet.medical_data.vaccines,
        nombrevacuna: '',
        fechavacuna: '',
        medicaciones: pet.medical_data.medications,
        nombremedicacion: '',
        dosismedicacion: '',
        frecuenciamedicacion: '',
        tipoalimento: pet.nutritional_data.type_food,
        cantidadalimento: pet.nutritional_data.count_food,
        marcaalimento: pet.nutritional_data.mark_food,
        contactos: pet.contact_data,
        nombrecontacto: '',
        telefonocontacto: '',
        parentescocontacto: '',
        nombredueño: owner.first_name + ' ' + owner.last_name,
        telefonodueño: owner.pet_owner.phone,
        email: owner.email
      }
      this.geoFindMe()
    }

  }
})
