



































import Vue from 'vue';
import Component from 'vue-class-component';
import {mapMutations} from "vuex";

@Component({
  methods: mapMutations('App', ["toggleDrawer"])
})
export default class NavBar extends Vue {
  lang = 'en'

  locale(value: string): void {
    this.$root.$i18n.locale = value
  }
}
