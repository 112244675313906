














































import Vue from "vue";
import {Auth} from "@/services/auth";


export default Vue.extend({
  props: {
    token: {
      type: String,
      default: ''
    }
  },
  i18n: {
    messages: {
      en:{
        titulo: 'New Password',
        contraseña: 'Password',
        botonenviar: 'Accept',
      },
      es: {
        titulo: 'Nueva Contraseña',
        contraseña: 'Contraseña',
        botonenviar: 'Aceptar',
      }
    }
  },
  data() {
    return {
      password: '',
      loading: false
    }
  },
  mounted() {
    if (!this.token) {
      this.$router.replace({name: 'Home'})
    }
  },
  methods: {
    enviar() {
      if (!this.password) return;
      const request = {
        token: this.token,
        password: this.password
      }
      this.loading = true
      Auth.change_password(request)
          .then(() => {
            this.$notify({
              title: 'Success'
            });
            this.$router.replace({name: 'Login'})
          })
          .catch(reason => {
            const message = reason.response.data.errors[
                Object.keys(reason.response.data.errors)[0]
                ][0]
            this.$notify({
              title: 'Error',
              text: message,
              type: 'error'
            });
          })
          .finally(() => {
            this.loading = false
          })
    }
  }

})
