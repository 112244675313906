<template>
  <v-row justify="center" style="margin-top: 30px">
    <v-col md="12">
      <v-card
          elevation="2"
          style="background-color: #FFFFFF"
          class="pa-3 rounded-xl"
      >
        <v-card-text>
          <h3 style="color:#4BABAC; margin-top:20px">
            {{ $t('title') }}
          </h3>
          <h2 style="color:#4BABAC">
            {{ userData.first_name }} {{ userData.last_name }}
          </h2>

          <v-row style="margin-top:25px">
            <v-col cols="12" md="6">
              <v-text-field
                  v-model="userData.username"
                  :label="labels.username()"
                  readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                  v-model="userData.first_name"
                  :label="labels.firstName()"
                  readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                  v-model="userData.last_name"
                  :label="labels.lastName()"
                  readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                  v-model="userData.email"
                  :label="labels.email()"
                  readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                  v-model="ownerData.address"
                  :label="labels.address()"
                  readonly
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                  v-model="ownerData.country"
                  :label="labels.country()"
                  readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                  v-model="ownerData.occupation"
                  :label="labels.occupation()"
                  readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                  v-model="ownerData.phone"
                  :label="labels.phone()"
                  readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                  v-model="ownerData.place_work"
                  :label="labels.placeWork()"
                  readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>

import {accountModel} from "@/models/Account.model";

export default {
  name: "detallecuidador",
  i18n: {
    messages: {
      ...accountModel.I18nMessages()
    }
  },
  data() {
    return {
      labels: accountModel.labels(this.$i18n),
      userData: {
        username: '',
        last_name: '',
        first_name: '',
        email_verified_at: '',
        updated_at: '',
        id: 0,
        created_at: '',
        email: ''
      },
      ownerData: {
        place_work: '',
        phone: '',
        occupation: '',
        country: '',
        code_register: '',
        address: '',
        active: true,
        id: 0,
        updated_at: '',
        created_at: ''
      }
    }
  },
  async mounted() {
    if (!this.$route.params.item) {
      await this.$router.replace({name: 'Home'})
    } else {
      this.userData = this.$route.params.item.user
      this.ownerData = this.$route.params.item.user.pet_owner
    }
  }
}
</script>

<style scoped>

</style>