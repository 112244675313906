import {RulesForm} from '@/support';

class PetModelModel {

    rules = () => {
        return {
            name: [
                ...RulesForm().required(),
                ...RulesForm().max(50)
            ],
            age: [
                ...RulesForm().min(0),
                ...RulesForm().requiredNumber(),
            ],
            specie: [
                ...RulesForm().required(),
                ...RulesForm().max(45)
            ],
            color: [
                ...RulesForm().required(),
                ...RulesForm().max(20)
            ],
            breed: [
                ...RulesForm().required(),
                ...RulesForm().max(80)
            ],
            weight: [
                ...RulesForm().required(),
                ...RulesForm().min(0)
            ],
            observation: [
                ...RulesForm().required()
            ],
            genre: [
                ...RulesForm().required()
            ],
            reward: [
                ...RulesForm().required()
            ],
            alergy: [
                ...RulesForm().required(),
                ...RulesForm().max(80)
            ],
            enfermedad: [
                ...RulesForm().required(),
                ...RulesForm().max(120)
            ],
            enfermedadobservacion: [
                ...RulesForm().required()
            ],
            vacuna: [
                ...RulesForm().required(),
                ...RulesForm().max(120)
            ],
            vacunafecha: [
                ...RulesForm().required()
            ],
            medicina: [
                ...RulesForm().required(),
                ...RulesForm().max(120)
            ],
            medicinadosis: [
                ...RulesForm().required(),
                ...RulesForm().max(120)
            ],
            medicinafrecuencia: [
                ...RulesForm().required(),
                ...RulesForm().max(120)
            ],
            nombrecontacto: [
                ...RulesForm().required(),
                ...RulesForm().max(120)
            ],
            telefonocontacto: [
                ...RulesForm().required(),
                ...RulesForm().max(120)
            ],
            parentescocontacto: [
                ...RulesForm().required(),
                ...RulesForm().max(120)
            ],
            estadoreproductivo: [
                ...RulesForm().max(30)
            ],
            desparasitante: [
                ...RulesForm().max(120)
            ],
            observacionesmedicas: [
                ...RulesForm().max(1000)
            ],
            tipoalimento: [
                ...RulesForm().max(120)
            ],
            code: [
                ...RulesForm().required(),
                ...RulesForm().min(6),
                ...RulesForm().max(7)
            ]
        }

    }
    /*rules = {
        name: [
            ...baseRulesForm().required(),
            ...baseRulesForm().max(50)
        ],
        age: [
            ...baseRulesForm().required(),
            ...baseRulesForm().min(0)
        ],
        specie: [
            ...baseRulesForm().required(),
            ...baseRulesForm().max(45)
        ],
        color: [
            ...baseRulesForm().required(),
            ...baseRulesForm().max(20)
        ],
        breed: [
            ...baseRulesForm().required(),
            ...baseRulesForm().max(80)
        ],
        weight: [
            ...baseRulesForm().required(),
            ...baseRulesForm().min(0)
        ],
        observation: [
            ...baseRulesForm().required()
        ],
        genre: [
            ...baseRulesForm().required()
        ],
        reward: [
            ...baseRulesForm().required()
        ],
        alergy: [
            ...baseRulesForm().required(),
            ...baseRulesForm().max(80)
        ],
        enfermedad: [
            ...baseRulesForm().required(),
            ...baseRulesForm().max(120)
        ],
        enfermedadobservacion: [
            ...baseRulesForm().required()
        ],
        vacuna: [
            ...baseRulesForm().required(),
            ...baseRulesForm().max(120)
        ],
        vacunafecha: [
            ...baseRulesForm().required()
        ],
        medicina: [
            ...baseRulesForm().required(),
            ...baseRulesForm().max(120)
        ],
        medicinadosis: [
            ...baseRulesForm().required(),
            ...baseRulesForm().max(120)
        ],
        medicinafrecuencia: [
            ...baseRulesForm().required(),
            ...baseRulesForm().max(120)
        ],
        nombrecontacto: [
            ...baseRulesForm().required(),
            ...baseRulesForm().max(120)
        ],
        telefonocontacto: [
            ...baseRulesForm().required(),
            ...baseRulesForm().max(120)
        ],
        parentescocontacto: [
            ...baseRulesForm().required(),
            ...baseRulesForm().max(120)
        ]
    }*/
}

const petModel = new PetModelModel()

export default petModel
