import {Profile, User} from "@/interfaces/User";
import {Role} from "@/interfaces/Role";
import {Module} from "vuex";
import {perfilService} from "@/services/Perfil";
import {PetOwner} from "@/interfaces/PetOwner";
import {UpdateProfile} from "@/interfaces/request/UpdateProfile";

interface AccountState {
    user: User;
    role: Role;
    owner: PetOwner
}

export const account: Module<AccountState, any> = {
    namespaced: true,
    state: {
        role: {
            id: 0,
            name: '',
            created_at: '',
            updated_at: ''
        },
        user: {
            id: 0,
            username: '',
            email: '',
            created_at: '',
            updated_at: '',
            email_verified_at: '',
            first_name: '',
            last_name: ''
        },
        owner: {
            id: 0,
            created_at: '',
            updated_at: '',
            active: false,
            address: '',
            code_register: '',
            country: '',
            occupation: '',
            phone: '',
            place_work: ''
        }
    },
    getters: {
        getRole: (state): string => {
            return state.role.name
        },
        getOwnerActive: (state): boolean => {
            return state.owner.active || false
        }
    },
    actions: {
        loadInfo: async ({commit}): Promise<Profile> => {
            return new Promise<Profile>((resolve, reject) => {
                perfilService.getInfo()
                    .then(value => {
                        commit('setUser', value)
                        commit('setRole', value.role)
                        if (value.pet_owner) {
                            commit('setOwner', value.pet_owner)
                        }
                        resolve(value)
                    })
                    .catch(reason => {
                        reject(reason)
                    })
            })
        },
        update: async ({commit}, payload: UpdateProfile) => {
            return new Promise<Profile>((resolve, reject) => {
                perfilService.updateProfile(payload)
                    .then(value => {
                        commit('setUser', value)
                        commit('setRole', value.role)
                        if (value.pet_owner) {
                            commit('setOwner', value.pet_owner)
                        }
                        resolve(value)
                    })
                    .catch(reason => {
                        reject(reason)
                    })
            })
        }
    },
    mutations: {
        setUser: (state, payload: User) => {
            state.user = payload
        },
        setRole: (state, payload: Role) => {
            state.role = payload
        },
        setOwner: (state, payload: PetOwner) => {
            state.owner = payload
        },
        setOwnerActive: (state, payload: boolean) => {
            state.owner.active = payload
        }
    }
}