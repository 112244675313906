


















































import Vue from "vue";
import {QrCodeService} from "@/services/QrCode";

export default Vue.extend({
  data() {
    return {
      cantidad: 0,
      codes: [],
      loading: false
    }
  },
  i18n: {
    messages: {
      en:{
        titulo: 'Generate QR Codes',
        cantidad: 'Quantity',
        botongenerar: 'Generate'
      },
      es: {
        titulo: 'Generar Códigos QR',
        cantidad: 'Cantidad',
        botongenerar: 'Exportar'
      }
    }
  },
  methods: {
    generar() {
      const request = {
        count: this.cantidad
      }
      this.loading = true
      QrCodeService.generar(request)
          .then(value => {
            this.codes = value
            this.cantidad = 0
          })
          .catch(reason => {
            if (reason.response.status === 422) {
              const message = reason.response.data.errors[
                  Object.keys(reason.response.data.errors)[0]
                  ][0]
              this.$notify({
                title: 'Error',
                text: message,
                type: 'error'
              });
            } else {
              this.$notify({
                title: 'Error',
                type: 'error'
              });
            }
          })
          .finally(() => {
        this.loading = false
      })
    }
  }
})

