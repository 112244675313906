




































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import {mapActions} from 'vuex';
import {petService} from '@/services/Pet';
import petModel from '@/models/PetModel.model';


@Component({
  methods: {
    ...mapActions('Auth', ['login'])
  },
  i18n: {
    messages: {
      en: {
        title: 'Login',
        boton: 'Accept',
        labels: {
          username: 'Username',
          password: 'Password'
        },
        rules: {
          username: 'Username is required',
          password: 'Password is required'
        },
        mensajecodigomanual: 'Can\'t scan the QR code?',
        mensajecodigomanual2: 'Enter here and push the code',
        titulopopup: 'Enter your pet QR code',
        codigo: 'Associated Code',
        botonvalidar: 'Submit',
        botoncancelar: 'Cancel',
      },
      es: {
        title: 'Ingresar',
        boton: 'Aceptar',
        labels: {
          username: 'Usuario',
          password: 'Contraseña'
        },
        rules: {
          username: 'Usuario es requerido',
          password: 'Contraseña es requerido'
        },
        mensajecodigomanual: '¿No puedes escanear el código QR?',
        mensajecodigomanual2: 'Entra aquí y coloca el código manualmente',
        titulopopup: 'Ingresa el Código del QR de tu Mascota',
        codigo: 'Código del QR Asociado',
        botonvalidar: 'Ingresar',
        botoncancelar: 'Cancelar',
      }
    }
  }
})
export default class Login extends Vue {
  form = false
  username = ''
  dialog = false
  usernameRules = [
    (v: string): string | boolean => !!v || this.$t('rules.username').toString()
  ]
  password = ''
  passwordRules = [
    (v: string): string | boolean => !!v || this.$t('rules.password').toString()
  ]

  loading = false

  login!: (data: any) => Promise<void>

  get formLogin(): Vue & { validate: () => boolean, reset(): void } {
    return this.$refs.login as Vue & { validate: () => boolean, reset(): void }
  }

  signIn(): void {
    if (this.formLogin.validate()) {
      const {username, password} = this
      this.loading = true
      this.login({username, password})
          .then(() => {
            this.$router.push('/')
          })
          .catch(reason => {
            if (reason.response && reason.response.status === 422) {
              const message = reason.response.data.errors[
                  Object.keys(reason.response.data.errors)[0]
                  ][0]
              this.$notify({
                title: 'Error',
                text: message,
                type: 'error'
              });
            } else {
              this.$notify({
                title: 'Error',
                type: 'error',
                text: 'Invalid Credentials'
              });
            }
          })
          .finally(() => {
            this.loading = false
          })
    }
  }

  registro = {
    qrcode: '',

  }

  rules =  petModel.rules()
  loadingValidate = false

  open() {
    this.dialog = true
  }

  formValidateCode(): Vue & { validate: () => boolean, reset(): void } {
    return this.$refs.formvalidatecode as Vue & { validate: () => boolean, reset(): void }
  }

  validateQR() {
    if (this.formValidateCode().validate()) {
      const request = {
        code: this.registro.qrcode
      }
      this.dialog = false
      this.$router.replace({name: 'loadescaner', params: {code: request.code}})
    }
  }

  cancelar() {
    this.dialog = false
  }
}
