import {en} from 'vuetify/src/locale'

export default {
    '$vuetify': {
        ...en
    },
    model: {
        auth: {
            labels: {
                username: 'Username',
                password: 'Password',
                passwordConfirmation: 'Password Confirmation',
                email: 'Email',
                firstName: 'First Name',
                lastName: 'Last Name'
            }
        }
    },
    form: {
        required: 'Is required',
        email: 'Email is invalid',
        max: {
            number: 'Max {value}',
            character: 'Max {value} characters'
        },
        min: {
            number: 'Min {value}',
            character: 'Min {value} characters'
        },
        checked: 'required check'
    },
    code: 'QR Code is required',
    yes: 'Yes',
    no: 'No',
    M: 'Male',
    F: 'Female'
}
