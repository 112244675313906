




import Vue from 'vue';
import {LocalStorage} from './services/LocalStorage';
import ApiService from "@/services/Api";
import axios from "axios";

export default Vue.extend({
  name: 'App',

  beforeMount() {
    if (LocalStorage.getToken() && LocalStorage.getRefreshToken()) {
      ApiService.setHeader()
    } else {
      ApiService.removeHeader()
    }

    axios.interceptors.response.use(
        value => {
          return value;
        },
        error => {
          if (error.response) {
            if (error.response.status === 401) {
              ApiService.removeHeader()
              LocalStorage.removeToken()
              LocalStorage.removeRefreshToken()
              this.$router.replace({name: 'Login'})
              return Promise.reject(error);
            } else {
              return Promise.reject(error);
            }
          } else {
            return Promise.reject(error);
          }
        }
    );
  }
});
