









































import Vue from 'vue';

export default Vue.extend({
  props: {
    code: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      pet2: {
        url: ''
      }
    }
  },
  i18n: {
    messages: {
      en: {
        registrarme: 'Register',
        cuenta: 'Login',
        mensaje: 'If this is your first time on the platform, please register. If you already have an account, we suggest you log in to add your new pet.'
      },
      es: {
        registrarme: 'Registrarme',
        cuenta: 'Ya Tengo Cuenta',
        mensaje: 'Si es su primera vez en la plataforma realice un registro, si usted ya posee una cuenta sugerimos ingresar ' +
            'para agregar su nueva mascota'
      }
    }
  },
  beforeMount() {
    if (this.$route.params.pet) {
      const pet: any = this.$route.params.pet
      this.pet2.url = pet.image_url
    } else  {
      this.$router.replace({name:'Home'})
    }
  },
  methods: {
    goRegistro() {
      this.$router.push({name: 'Registrar', params: {code: this.code}})
    }
  }

})
