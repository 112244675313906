




























































































import Vue from "vue";
import Component from "vue-class-component";
import {mapActions, mapState} from "vuex";
import {Profile, User} from "@/interfaces/User";
import {PetOwner} from "@/interfaces/PetOwner";
import {Role} from "@/interfaces/Role";
import {accountModel} from "@/models/Account.model";
import {UpdateProfile} from "@/interfaces/request/UpdateProfile";


@Component({
  computed: {
    ...mapState('account', ['user', 'owner', 'role'])
  },
  i18n: {
    messages: {
      ...accountModel.I18nMessages() 
    }
  },
  methods: {
    ...mapActions('account', ['update'])
  }
})
export default class Perfil extends Vue {
  user!: User
  owner!: PetOwner
  role!: Role

  labels = accountModel.labels(this.$i18n)

  update!: (data: UpdateProfile) => Promise<Profile>

  userData: User = {
    username: '',
    last_name: '',
    first_name: '',
    email_verified_at: '',
    updated_at: '',
    id: 0,
    created_at: '',
    email: ''
  }
  ownerData: PetOwner = {
    place_work: '',
    phone: '',
    occupation: '',
    country: '',
    code_register: '',
    address: '',
    active: true,
    id: 0,
    updated_at: '',
    created_at: ''
  }
  roleData: Role = {
    updated_at: '',
    id: 0,
    created_at: '',
    name: ''
  }

  loading = false

  mounted() {
    this.$i18n
    this.userData = this.user
    this.ownerData = this.owner
    this.roleData = this.role
  }

  updateProfile() {
    const request: UpdateProfile = {
      address: this.ownerData.address || '',
      country: this.ownerData.country || '',
      email: this.userData.email,
      firstName: this.userData.first_name,
      lastName: this.userData.last_name,
      occupation: this.ownerData.occupation || '',
      phone: this.ownerData.phone || '',
      placeWork: this.ownerData.place_work || '',
      username: this.userData.username
    }
    this.loading = true
    this.update(request)
        .then(() => {
          this.$notify({
            title: 'Success',
            text: 'Profile Update'
          })
        })
        .catch(reason => {
          const message = reason.response.data.errors[
              Object.keys(reason.response.data.errors)[0]
              ][0]
          this.$notify({
            title: 'Error',
            text: message,
            type: 'error'
          });
        })
        .finally(() => {
          this.loading = false
        })
  }
}
