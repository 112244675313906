<template>
  <v-app>
    <div class="fill-height" v-if="loading">
      <v-row
          align="center"
          align-content="center"
          class="fill-height"
          justify="center"
      >
        <v-progress-circular class="align-self-center" color="primary" indeterminate size="150" width="2">
          <v-progress-circular color="secondary" indeterminate rotate="180" size="120"
            width="1"></v-progress-circular>
        </v-progress-circular>
      </v-row>

    </div>
    <nav-bar v-if="!loading"/>
    <core-menu></core-menu>
    <main-container v-if="!loading"></main-container>
    <notifications position="bottom right" v-if="!loading"/>
  </v-app>
</template>

<script>
import MainContainer from "@/components/layouts/main-container";
import NavBar from "@/components/layouts/nav-bar";
import {perfilService} from "@/services/Perfil";
import {mapActions} from "vuex";

export default {
  name: "layout-main",
  components: {NavBar, MainContainer, },
  data() {
    return {
      loading: true
    }
  },
  beforeMount() {
    this.loadInfo()
    .finally(() => {
      this.loading = false
    })
  },
  methods: {
    ...mapActions('account',['loadInfo'])
  }
}
</script>

<style scoped>

</style>
