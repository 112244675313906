

























































































import Vue from 'vue';

export default Vue.extend({
  name: 'nav-bar-auth',
  data() {
    return {
      lang: 'en',
      items: [
        {
          title: ()=>this.$t('ingresar'),
          to: {name: 'Login'}
        },
        {
          title: ()=>this.$t('registrar'),
          to: {name: 'Registrar'}
        },
        {
          title: ()=>this.$t('recuperar'),
          to: {name: 'Recuperar'}
        }
      ]
    }
  },
  i18n: {
    messages: {
      en:{
        ingresar: 'Login',
        registrar: 'Register',
        recuperar: 'Recover Password',
        
      },
      es: {
        ingresar: 'Ingresar',
        registrar: 'Registrarse',
        recuperar: 'Recuperar Contraseña',
      }
    }
  },
  methods: {
    locale(value: string) {
      this.$root.$i18n.locale = value
    }
  }
})
