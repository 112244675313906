import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import LayoutMain from '@/components/layouts/layout-main.vue';
import LayoutAuth from '@/components/layouts/layout-auth.vue';
import LayoutQr from '@/components/layouts/layout-qr.vue';
import Home from '@/views/Home.vue';
import Perfil from '@/views/Perfil.vue';
import RegistrarMascota from '@/views/RegistrarMascota.vue';
import ListarMascota from '@/views/ListarMascota.vue';
import DetalleMascota from '@/views/DetalleMascota.vue';
import Login from '@/views/auth/Login.vue';
import Recuperar from '@/views/auth/Recuperar.vue';
import CorreoRecuperar from '@/views/auth/CorreoRecuperar.vue';
import Registrar from '@/views/auth/Registrar.vue';
import {LocalStorage} from '@/services/LocalStorage';
import QrDisponibles from "@/views/Admin/Qrcode/QrDisponibles.vue";
import QrIndex from "@/views/Admin/Qrcode/QrIndex.vue";
import QrMain from "@/views/Admin/Qrcode/QrMain.vue";
import QrVendidos from "@/views/Admin/Qrcode/QrVendidos.vue";
import QrGenerar from "@/views/Admin/Qrcode/QrGenerar.vue";
import QrVitrina from "@/views/Admin/Qrcode/QrVitrina.vue";
import PreguntaEscaneo from "@/views/PreguntaEscaneo.vue";
import DetalleEscaneo from "@/views/DetalleEscaneo.vue";
import LoadEscaner from "@/views/LoadEscaner.vue";
import DetalleCuidador from "@/views/detallecuidador.vue";



Vue.use(VueRouter)

const routes: Array<RouteConfig> = [

    {
        path: '/',
        component: LayoutMain,
        meta: {
            Auth: true,
        },
        
        children: [
            {
                path: '',
                component: Home,
                name: 'Home'
            },
            {
                path: '/profile',
                component: Perfil,
                name: 'Perfil'
            },
            {
                path: '/petregister',
                component: RegistrarMascota,
                name: 'RegistrarMascota',
                props: true
            },
            {
                path: '/petlist',
                name: 'ListarMascota',
                component: ListarMascota
            },
            {
                path: '/detailpet',
                name: 'DetalleMascota',
                component: DetalleMascota
            },
            
            {
                path: '/qr',
                component: QrIndex,
                children: [
                    {
                        path: '/',
                        name: 'QrMain',
                        component: QrMain
                    },
                    {
                        path: 'available',
                        name: 'QrAvailable',
                        component: QrDisponibles
                    },
                    {
                        path: 'sold',
                        name: 'QrSold',
                        component: QrVendidos
                    },
                    {
                        path: 'showcase',
                        name: 'QrVitrina',
                        component: QrVitrina
                    },
                    {
                        path: 'generate',
                        name: 'QrGenerar',
                        component: QrGenerar
                    },
                    {
                        path: 'show',
                        name: 'ShowCuidador',
                        component: DetalleCuidador,
                        props: true
                    }
                ]
            }
        ]
    },
    {
        path: '/auth',
        component: LayoutAuth,
        meta: {
            Guest: true
        },
        children: [
            {
                path: '',
                redirect: '/auth/login'
            },
            {
                path: 'recovery',
                name: 'Recuperar',
                component: Recuperar,
                meta: {
                    Guest: true
                }
            },
            {
                path: 'password_reset/:token',
                name: 'Recuperar2',
                component: CorreoRecuperar,
                meta: {
                    Guest: true
                },
                props: true
            },
            {
                path: 'register',
                name: 'Registrar',
                component: Registrar,
                meta: {
                    Guest: true
                },
                props: route => ({code: route.params.code})
            },
            {
                path: 'login',
                name: 'Login',
                component: Login,
                meta: {
                    Guest: true
                }
            },
        ]
    },
    {
        path: '/woof',
        component: LayoutQr,
        children: [
            {
                path: '',
                redirect: '/auth/login'
            },
            {
                path: ':code',
                name: 'loadescaner',
                component: LoadEscaner,
                props: true
            },
            {
                path: ':code/register',
                name: 'preguntaescaner',
                component: PreguntaEscaneo,
                props: true
            },
            {
                path: ':code/view',
                name: 'detalleescaneo',
                component: DetalleEscaneo,
                props: true
            }
        ]
    }

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(value => value.meta.Auth)) {

        if (LocalStorage.getToken() == null || LocalStorage.getToken() === '') {
            next({
                name: 'Login',
                params: {nextUrl: to.fullPath}
            })
        } else {
            next()
        }
    } else if (to.matched.some(record => record.meta.Guest)) {
        if (LocalStorage.getToken() == null || LocalStorage.getToken() === '') {
            next()
        } else {
            next({
                path: '/',
                params: {nextUrl: to.fullPath}
            })
        }
    } else {
        next()
    }
})

export default router
