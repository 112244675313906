import Api from "@/services/Api";

const petService = {
    validateCodeToRegister: async (data: {code: string}): Promise<any> => {
        const response = await Api.post('pet/validate', data)
        return response.data
    },
    registro : async (data: any): Promise<any> => {
        const response = await Api.post('pet/register', data)
        return response.data
    },
    actualizar : async (data: any, id: number): Promise<any> => {
        const response = await Api.post('pet/'+ id +'/update', data)
        return response.data
    },
    cambiarfoto : async (data: any, id: number): Promise<any> => {
        const response = await Api.post('pet/'+ id +'/photo', data)
        return response.data
    },
    mismascotas : async (): Promise<any> => {
        const response = await Api.get('pet/list')
        return response.data
    }
}

export {petService}