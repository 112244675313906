






































































import Vue from "vue";
import Component from "vue-class-component";
import {QrCode} from "@/interfaces/QrCode";
import {QrCodeService} from "@/services/QrCode";

@Component({
  i18n: {
    messages: {
      en:{
        titulo: 'List of Sold QR Codes',
        botonatras: 'Back',
        botonexportar: 'Export'
      },
      es: {
        titulo: 'Listado de Códigos QR Vendidos',
        botonatras: 'Atrás',
        botonexportar: 'Exportar'
      }
    }
  }
})
export default class QrVendidos extends Vue {
  codes: any[] = []
  loading = false
  loadingExport = false
  total = 0
  page = 0
  nextpage = ''
  prevpage = ''
  basepage = ''


  beforeMount(){
    this.loadCodes()
  }

  loadCodes() {
    this.loading = true
    QrCodeService.getVendidos()
        .then(value => {
          this.codes = value.data
          this.total = value.meta.last_page
          this.page = value.meta.current_page
          this.nextpage = value.links.next || ''
          this.prevpage = value.links.prev || ''
          this.basepage = value.meta.path
        })
        .catch(reason => {
          this.codes = []
        })
        .finally(() => {
          this.loading = false
        })
  }

  exportbycode(code: string) {
    const request = {
      code
    }
    this.loadingExport = true
    QrCodeService.exportarBycode(request)
        .then(response => {
          let fileName = response.headers["content-disposition"].split("filename=")[1];
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName); //or any other extension
          document.body.appendChild(link);
          link.click();
          this.loadCodes()
        })
        .catch(reason => {
          if (reason.response.status === 422) {
            const message = reason.response.data.errors[
                Object.keys(reason.response.data.errors)[0]
                ][0]
            this.$notify({
              title: 'Error',
              text: message,
              type: 'error'
            });
          } else {
            this.$notify({
              title: 'Error',
              type: 'error'
            });
          }
        })
        .finally(() => {
          this.loadingExport = false
        })
  }

  showinfo(item: any){
    this.$router.replace({name: 'ShowCuidador', params: {item: item}})
  }

  loadNext() {
    if (this.nextpage) {
      this.loading = true

      QrCodeService.pagination(this.nextpage)
          .then(value => {
            this.codes = value.data
            this.total = value.meta.last_page
            this.page = value.meta.current_page
            this.nextpage = value.links.next || ''
            this.prevpage = value.links.prev || ''
            this.basepage = value.meta.path
          })
          .catch(reason => {
            this.codes = []
          })
          .finally(() => {
            this.loading = false
          })
    }

  }

  loadPrev() {
    if (this.prevpage) {
      this.loading = true

      QrCodeService.pagination(this.prevpage)
          .then(value => {
            this.codes = value.data
            this.total = value.meta.last_page
            this.page = value.meta.current_page
            this.nextpage = value.links.next || ''
            this.prevpage = value.links.prev || ''
            this.basepage = value.meta.path
          })
          .catch(reason => {
            this.codes = []
          })
          .finally(() => {
            this.loading = false
          })
    }

  }

  changePagination(event: number) {
    this.loading = true

    QrCodeService.pagination(this.basepage + '?page=' + event)
        .then(value => {
          this.codes = value.data
          this.total = value.meta.last_page
          this.page = value.meta.current_page
          this.nextpage = value.links.next || ''
          this.prevpage = value.links.prev || ''
          this.basepage = value.meta.path
        })
        .catch(reason => {
          this.codes = []
        })
        .finally(() => {
          this.loading = false
        })
  }
}
