import i18n from '@/i18n';

const RulesForm = () => {
    return {
        required: (name = 'form.required') => [
            (v: string): string | boolean => !!v || i18n.t(name).toString()
        ],
        requiredNumber: (name = 'form.required') => [
            (v: string | number): string | boolean => (typeof v === 'string' ? !!v : v != null) || i18n.t(name).toString()
        ],
        email: (name = 'form.email') => [
            (v: string): string | boolean => /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/.test(v) || i18n.t(name).toString()
        ],
        max: (value: number | string, name = 'form.max.character', name2 = 'form.max.number') => [
            (v: number | string): string | boolean => typeof v === 'string' ?
                (v.length <= value || i18n.t(name, {value}).toString()) :
                (v <= value || i18n.t(name2, {value}).toString())
        ],
        min: (value: number | string, name = 'form.min.character', name2 = 'form.min.number') => [
            (v: number | string): string | boolean => typeof v === 'string' ?
                (v.length >= value || i18n.t(name, {value}).toString()) :
                (v >= value || i18n.t(name2, {value}).toString())
        ],
        check: (name = 'form.check') => [
            (v: any): string | boolean => !!v || i18n.t(name).toString()
        ]
    }
}

export {RulesForm}
