import {RulesForm} from '@/support';
import I18n from '@/i18n';

class AuthModel {

    registerRules = {
        username : [
            ...RulesForm().required(),
            ...RulesForm().max(50)
        ],
        email: [
            ...RulesForm().required(),
            ...RulesForm().max(191),
            ...RulesForm().email()
        ],
        firstName: [
            ...RulesForm().required(),
            ...RulesForm().max(50)
        ],
        lastName: [
            ...RulesForm().required(),
            ...RulesForm().max(50)
        ],
        password: [
            ...RulesForm().required(),
            ...RulesForm().min(6),
            ...RulesForm().max(20)
        ],
        qrcode: [
            ...RulesForm().required('code'),
            ...RulesForm().max(15)
        ]
    }

    labels = {
        username: () => I18n.t('model.auth.labels.username'),
        password: () => I18n.t('model.auth.labels.password'),
        email: () => I18n.t('model.auth.labels.email'),
        firstName: () => I18n.t('model.auth.labels.firstName'),
        lastName: () => I18n.t('model.auth.labels.lastName'),
    }
}

const authModel = new AuthModel()

export default authModel
