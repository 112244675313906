import Api from "@/services/Api";
import {PaginationCollection} from "@/interfaces/PaginationCollection";
import {QrCode} from "@/interfaces/QrCode";
import {AxiosResponse} from "axios";

const QrCodeService = {
    getAvailable: async (): Promise<PaginationCollection<QrCode>> => {
        const response = await Api.get<PaginationCollection<QrCode>>('admin/qr/available')
        return response.data
    },
    pagination: async (url: string): Promise<PaginationCollection<QrCode>> => {
        const response = await Api.getOption<PaginationCollection<QrCode>>(url, {url:''})
        return response.data
    },
    getVitrina: async (): Promise<PaginationCollection<QrCode>> => {
        const response = await Api.get<PaginationCollection<QrCode>>('admin/qr/showcase')
        return response.data
    },
    getVendidos: async (): Promise<PaginationCollection<QrCode>> => {
        const response = await Api.get<PaginationCollection<QrCode>>('admin/qr/sold')
        return response.data
    },
    count: async (): Promise<any> => {
        const response = await Api.get<any>('admin/qr/count')
        return response.data
    },
    generar: async (data: any): Promise<any> => {
        const response = await Api.post('admin/qr/generate', data)
        return response.data
    },
    exportar: async (data: any): Promise<AxiosResponse> => {
        return await Api.postOption('admin/qr/export', data, {responseType: 'blob'})
    },
    exportarBycode: async (data: any): Promise<AxiosResponse> => {
        return await Api.postOption('admin/qr/exportbycode', data, {responseType: 'blob'})
    },
    statusEscaner: async (data: {code: string}): Promise<any> => {
        const response = await Api.post('qr/status', data)
        return response.data
    },
    sendPosition: async (data: any): Promise<any> => {
        const response = await Api.post('qr/sendPosition', data)
        return response.data
    }
}
export {QrCodeService}