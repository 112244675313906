<template>
  <v-app>
    <nav-bar-qr/>
    <main-qr></main-qr>
    <notifications position="bottom right"/>
  </v-app>
</template>

<script>
import MainQr from "@/components/layouts/main-qr.vue";
import NavBarQr from "@/components/layouts/nav-bar-qr.vue";

export default {
  name: "layout-qr",
  components: {NavBarQr, MainQr}
}
</script>

<style scoped>

</style>
